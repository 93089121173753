// Copyright (C) 2024 Xtremis, All rights reserved

import { API_URL } from "./constants";

export const updateTaggingStatus = async (path: string, status: string) => {
    let response = await fetch(`${API_URL}/updateTaggingStatus`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ path: path, status: status }),
        credentials: "include",
    });
    if (!response.ok) {
        throw new Error("Failed to update tagging status");
    }
};
