// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useRef, useEffect } from "react";
import { useCacheStore } from "../utils/store";
import { hertz_divider, hertz_unit, HORIZONTAL_AXIS_HEIGHT } from "../utils/constants";

const HorizontalAxis: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { heatmapData, visibleArea, canvasSize } = useCacheStore();

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        if (!heatmapData) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        if (canvas.width !== canvasSize.width) {
            canvas.width = canvasSize.width;
            canvas.height = HORIZONTAL_AXIS_HEIGHT;
        }

        ctx.clearRect(0, 0, canvasSize.width, HORIZONTAL_AXIS_HEIGHT);

        ctx.fillStyle = "#fff";
        ctx.strokeStyle = "#fff";
        ctx.font = "11px Arial";
        ctx.textAlign = "center";
        ctx.textBaseline = "top";

        const totalTicks = Math.floor(canvas.width / 80);
        const visibleFreqRange =
            heatmapData.start_freq +
            (visibleArea.toCol / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq) -
            (heatmapData.start_freq +
                (visibleArea.fromCol / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq));

        // Quantize step size
        const tickStep = quantizeStep(visibleFreqRange / (totalTicks - 1));

        // Start at the visible area's start frequency, rounded to the nearest tick step
        const startValue =
            Math.ceil(
                (heatmapData.start_freq +
                    (visibleArea.fromCol / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq)) /
                    tickStep,
            ) * tickStep;

        // Calculate and render ticks
        for (let i = 0; i < totalTicks; i++) {
            const tickValue = startValue + i * tickStep;

            // Map tick value to canvas X position
            const normalizedTick =
                ((tickValue - heatmapData.start_freq) / (heatmapData.end_freq - heatmapData.start_freq)) *
                heatmapData.width;

            const x =
                ((normalizedTick - visibleArea.fromCol) / (visibleArea.toCol - visibleArea.fromCol)) * canvasSize.width;

            // Ensure X is within canvas bounds
            if (x >= 0 && x <= canvasSize.width) {
                // Draw tick line
                ctx.beginPath();
                ctx.moveTo(x, 0);
                ctx.lineTo(x, 10);
                ctx.stroke();

                // Draw tick label
                ctx.fillText(formatLabel(tickValue), x, 12);
            }
        }
    }, [visibleArea, canvasSize.width, heatmapData]);

    const quantizeStep = (step: number): number => {
        const STEP_DIVIDER = 100000;
        return Math.floor(step / STEP_DIVIDER) * STEP_DIVIDER;
    };

    const formatLabel = (value: number): string => {
        return `${(value / hertz_divider).toFixed(1)} ${hertz_unit}`;
    };

    return <canvas ref={canvasRef} style={{ border: "opx", height: `${HORIZONTAL_AXIS_HEIGHT}px`, width: "100%" }} />;
};

export default HorizontalAxis;
