// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useEffect, useRef, useState } from "react";
import { useCacheStore } from "../utils/store";
import { normalizeData, createBluishImage, imageDataToBlob } from "../utils/utils";
import "./Minimap.css";

const Minimap: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const { heatmapData, visibleArea, selectedAntenna, selectedAggregation } = useCacheStore();

    const [image, setImage] = useState<HTMLImageElement | null>(null);

    useEffect(() => {
        if (!canvasRef.current || !heatmapData || heatmapData.width == null || heatmapData.height == null) return;

        let isMounted = true;

        // Obtain the image data
        const imageData = heatmapData.images?.[selectedAntenna]?.[selectedAggregation];

        if (!imageData) {
            console.error("Image data is not available.");
            return;
        }

        // Capture width and height in constants
        const width = heatmapData.width;
        const height = heatmapData.height;

        // Process the image data to create an image
        async function processAndCreateImage() {
            const normalized = normalizeData(imageData!);
            const bluish = createBluishImage(normalized, width, height);
            const blob = await imageDataToBlob(bluish);
            const blobUrl = URL.createObjectURL(blob);

            const img = new Image();
            img.src = blobUrl;
            img.onload = () => {
                if (isMounted) {
                    setImage(img);
                }
                URL.revokeObjectURL(blobUrl);
            };
        }

        processAndCreateImage().catch((error) => {
            console.error("Error processing image data for minimap:", error);
        });

        return () => {
            isMounted = false;
        };
    }, [heatmapData, selectedAntenna, selectedAggregation]);

    useEffect(() => {
        if (!canvasRef.current || !heatmapData || !image) return;

        const canvas = canvasRef.current;
        const parent = canvas.parentElement;
        if (!parent) return;

        // Set canvas dimensions
        canvas.width = parent.clientWidth;

        const aspectRatio = heatmapData.height / heatmapData.width;
        canvas.height = canvas.width * aspectRatio;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw the heatmap image scaled to the canvas size
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        const scaleX = canvas.width / heatmapData.width;
        const scaleY = canvas.height / heatmapData.height;

        const { fromCol, toCol, fromRow, toRow } = visibleArea;

        // Calculate the visible area rectangle
        const visibleX = fromCol * scaleX;
        const visibleY = fromRow * scaleY;
        const visibleWidth = (toCol - fromCol) * scaleX;
        const visibleHeight = (toRow - fromRow) * scaleY;

        // Draw the visible area rectangle
        ctx.strokeStyle = "#ff0000";
        ctx.lineWidth = 2;
        ctx.strokeRect(visibleX, visibleY, visibleWidth, visibleHeight);
    }, [heatmapData, visibleArea, image]);

    return (
        <>
            {heatmapData && (
                <canvas
                    ref={canvasRef}
                    className="minimap-canvas"
                    aria-label="Minimap showing the heatmap with the current view area highlighted"
                />
            )}
        </>
    );
};

export default Minimap;
