// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useState, useRef } from "react";
import MainCanvas from "./MainCanvas";
import Histogram from "./Histogram";
import VerticalAxis from "./VerticalAxis";
import HorizontalAxis from "./HorizontalAxis";
import LongVoxelPanel from "./LongVoxelPanel";
import HelpMenu from "./HelpMenu";
import { useCacheStore } from "../utils/store";
import "./DataHandlingPanel.css";

interface DataHandlingPanelProps {
    onLogout: () => void;
}
const DataHandlingPanel: React.FC<DataHandlingPanelProps> = ({ onLogout }) => {
    const { selectedAntenna, selectedAggregation, loading, error, heatmapData } = useCacheStore();

    const dataHandlingPanelRef = useRef<HTMLDivElement>(null);

    const [showHelp, setShowHelp] = useState(false);

    if (loading) {
        return (
            <div className="data-handling-panel loading">
                <div className="spinner"></div>
            </div>
        );
    }
    if (error) {
        return <div className="data-handling-panel data-handling-message">{error}</div>;
    }
    return (
        <>
            {heatmapData && heatmapData.images[selectedAntenna][selectedAggregation] && (
                <div
                    ref={dataHandlingPanelRef}
                    className = "data-handling-panel"
                >
                    {/* Help Pictogram */}
                    <div id="info-icon" onClick={() => setShowHelp(!showHelp)} title="Help" />

                    {showHelp && <HelpMenu onClose={() => setShowHelp(false)} onLogout={onLogout} />}

                    <Histogram />

                    <LongVoxelPanel numBins={heatmapData.width} imageWidth={heatmapData.width} />
                    <div
                        className="long-voxel-panel"
                    >
                        <VerticalAxis />
                        <MainCanvas
                            imageData={heatmapData.images[selectedAntenna][selectedAggregation]!}
                            imageHeight={heatmapData.height}
                            imageWidth={heatmapData.width}
                        />
                        <div
                            className="horizontal-axis-container"
                        >
                            <HorizontalAxis />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DataHandlingPanel;
