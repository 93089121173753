// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useRef, useEffect } from "react";
import { useCacheStore } from "../utils/store";
import { MAIN_CANVAS_AXIS_SIZE } from "../utils/constants";
import "./VerticalAxis.css"

const VerticalAxis: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { heatmapData, visibleArea, canvasSize } = useCacheStore();

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        if (canvas.height !== canvasSize.height) {
            canvas.height = canvasSize.height;
            canvas.width = MAIN_CANVAS_AXIS_SIZE;
        }

        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        if (!heatmapData) return;

        ctx.clearRect(0, 0, MAIN_CANVAS_AXIS_SIZE, canvasSize.height);

        ctx.fillStyle = "#fff";
        ctx.strokeStyle = "#fff";
        ctx.font = "11px Arial";
        ctx.textAlign = "right";
        ctx.textBaseline = "middle";

        const totalTicks = canvasSize.height / 25; // Fixed number of ticks
        const visibleTimeRange =
            heatmapData.start_time +
            (visibleArea.toRow / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time) -
            (heatmapData.start_time +
                (visibleArea.fromRow / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time));

        // Quantize step size
        const tickStep = quantizeStep(visibleTimeRange / (totalTicks - 1));

        // Start at the visible area's start time, rounded to the nearest tick step
        const startValue =
            Math.ceil(
                (heatmapData.start_time +
                    (visibleArea.fromRow / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time)) /
                    tickStep,
            ) * tickStep;

        // Calculate and render ticks
        for (let i = 0; i < totalTicks; i++) {
            const tickValue = startValue + i * tickStep;

            // Map tick value to canvas Y position
            const normalizedTick =
                ((tickValue - heatmapData.start_time) / (heatmapData.end_time - heatmapData.start_time)) *
                heatmapData.height;

            const y =
                ((normalizedTick - visibleArea.fromRow) / (visibleArea.toRow - visibleArea.fromRow)) *
                canvasSize.height;

            // Ensure Y is within canvas bounds
            if (y >= 0 && y <= canvasSize.height) {
                // Draw tick line
                ctx.beginPath();
                ctx.moveTo(MAIN_CANVAS_AXIS_SIZE - 8, y);
                ctx.lineTo(MAIN_CANVAS_AXIS_SIZE, y);
                ctx.stroke();

                // Draw tick label
                ctx.fillText(formatLabel(tickValue), MAIN_CANVAS_AXIS_SIZE - 12, y);
            }
        }
    }, [visibleArea, MAIN_CANVAS_AXIS_SIZE, canvasSize.height]);

    const quantizeStep = (step: number): number => {
        const STEP_DIVIDER = 10;
        return Math.round(step * STEP_DIVIDER) / STEP_DIVIDER;
    };

    const formatLabel = (value: number): string => {
        return `${value.toFixed(1)} s`;
    };

    return (
        <canvas
            ref={canvasRef}
            className="vertical-axis-container"
        />
    );
};

export default VerticalAxis;
