/* Copyright (C) 2024 Xtremis, All rights reserved*/

import React, { useRef, useState, useEffect, useCallback } from "react";
import {
    calculateMousePosition,
    createBluishImage,
    imageDataToBlob,
    isWithinImageBound,
    normalizeData,
    sideToHoverCorner,
    updateBoxByCorner,
} from "../utils/utils";
import { calculateBoundingBoxStatistics, getHeatmapCell, drawVoxels, drawCrossLines } from "../utils/drawutils";
import { useCacheStore } from "../utils/store";
import { MAX_ZOOM } from "../utils/constants";
import "./MainCanvas.css"

interface CanvasProps {
    imageData: Float32Array;
    imageWidth: number;
    imageHeight: number;
}

const MainCanvas: React.FC<CanvasProps> = ({ imageData, imageWidth, imageHeight }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const isPanning = useRef(false);
    const panStart = useRef({ x: 0, y: 0 });

    const {
        heatmapData,
        hoverCoords,
        setHoverCoords,
        setVisibleArea,
        canvasSize,
        setCanvasSize,
        scale,
        offset,
        setScale: onScaleChange,
        setOffset: onOffsetChange,
        scaleOffsetInitialized,
        setScaleOffsetInitialized,
        voxels,
        persistVoxels,
        updateVoxel,
        getNextBoxId,
        setVoxelSelected,
        deselectVoxels,
        deleteSelectedVoxel,
        undo,
    } = useCacheStore();
    const { showVoxels, editingMode } = useCacheStore();

    // State for bounding boxes
    const [newBoxId, setNewBoxId] = useState<number | null>(null);

    const [hoveredBBox, setHoveredBBox] = useState<{
        position: { x: number; y: number };
        stats: HoveredStats;
    } | null>(null);

    const [hoveredCorner, setHoveredCorner] = useState<{
        bboxId: number;
        corner: HoverCorner;
    } | null>(null);

    const [resizingState, setResizingState] = useState<{
        bboxId: number;
        corner: HoverCorner;
    } | null>(null);

    const [hoveredSide, setHoveredSide] = useState<{
        bboxId: number;
        side: Directions;
    } | null>(null);

    useEffect(() => {
        let timeoutId: number | null = null;
        const canvasElement = canvasRef.current;
        if (!canvasElement) return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                timeoutId = window.setTimeout(() => {
                    if (canvasElement.width !== Math.floor(width) || canvasElement.height !== Math.floor(height)) {
                        canvasElement.width = width;
                        canvasElement.height = height;
                        setCanvasSize({ width, height });

                        const minScaleX = canvasElement.width / imageWidth;
                        const minScaleY = canvasElement.height / imageHeight;

                        const newScaleX = Math.max(scale.x, minScaleX);
                        const newScaleY = Math.max(scale.y, minScaleY);

                        onScaleChange({ x: newScaleX, y: newScaleY });
                    }
                }, 5);
            }
        });
        resizeObserver.observe(canvasElement);
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            resizeObserver.disconnect();
        };
    }, []);

    const drawImage = useCallback(() => {
        if (!canvasRef.current || !image) return;
        if (!scale || !offset) return;
        const ctx = canvasRef.current.getContext("2d");
        if (!ctx) return;

        ctx.clearRect(0, 0, canvasSize.width, canvasSize.height);

        const scaledWidth = image.width * scale.x;
        const scaledHeight = image.height * scale.y;

        const clampedOffsetX = Math.max(Math.min(offset.x, 0), canvasSize.width - scaledWidth);
        const clampedOffsetY = Math.max(Math.min(offset.y, 0), canvasSize.height - scaledHeight);
        ctx.imageSmoothingEnabled = false;

        ctx.drawImage(image, clampedOffsetX, clampedOffsetY, scaledWidth, scaledHeight);

        if (showVoxels) {
            // User-drawn bounding boxes
            const centerY = canvasSize.height / 2;
            voxels.forEach((bbox) => {
                drawVoxels(ctx, bbox, scale, offset, centerY, resizingState, hoveredCorner);
            });
        }

        drawCrossLines(ctx, image, editingMode, hoverCoords, scale, clampedOffsetX, clampedOffsetY);
    }, [canvasRef, image, scale, offset, canvasSize, showVoxels, heatmapData, hoverCoords, voxels, resizingState]);

    useEffect(() => {
        // Reset resizingState if bboxId is invalid
        if (resizingState && !voxels.find((bbox) => bbox.id === resizingState.bboxId)) {
            setResizingState(null);
        }

        // Reset hoveredCorner if bboxId is invalid
        if (hoveredCorner && !voxels.find((bbox) => bbox.id === hoveredCorner.bboxId)) {
            setHoveredCorner(null);
        }
    }, [resizingState, hoveredCorner]);

    useEffect(() => {
        if (!image || canvasSize.width === 0 || canvasSize.height === 0) return;
        if (!scaleOffsetInitialized) {
            const minScaleX = canvasSize.width / image.width;
            const minScaleY = canvasSize.height / image.height;

            // Set scale to fill the canvas
            const newScaleX = minScaleX;
            const newScaleY = minScaleY;

            onScaleChange({ x: newScaleX, y: newScaleY });

            // Center the image
            const scaledWidth = image.width * newScaleX;
            const scaledHeight = image.height * newScaleY;

            const offsetX = (canvasSize.width - scaledWidth) / 2;
            const offsetY = (canvasSize.height - scaledHeight) / 2;

            onOffsetChange({ x: offsetX, y: offsetY });
            setScaleOffsetInitialized(true);
        }
    }, [image, canvasSize.width, canvasSize.height, onScaleChange, onOffsetChange]);

    useEffect(() => {
        async function processAndRenderImage(imageData: Float32Array, width: number, height: number) {
            const normalized = normalizeData(imageData);
            const bluish = createBluishImage(normalized, width, height);
            const blob = await imageDataToBlob(bluish);
            const blobUrl = URL.createObjectURL(blob);

            const img = new Image();
            img.src = blobUrl;
            img.onload = () => {
                if (!canvasRef.current) return;
                setImage(img);
                URL.revokeObjectURL(blobUrl);
            };
        }
        processAndRenderImage(imageData, imageWidth, imageHeight).catch((error) => {
            console.error("Error processing image data:", error);
        });
    }, [imageData, imageWidth, imageHeight]);

    useEffect(() => {
        if (!image) return;
        if (!scale || !offset) return;

        const visibleFromX = Math.max(0, -offset.x / scale.x);
        const visibleToX = Math.min(image.width, (-offset.x + canvasSize.width) / scale.x);

        const visibleFromY = Math.max(0, -offset.y / scale.y);
        const visibleToY = Math.min(image.height, (-offset.y + canvasSize.height) / scale.y);

        setVisibleArea({
            fromRow: Math.floor(visibleFromY),
            toRow: Math.ceil(visibleToY),
            fromCol: Math.floor(visibleFromX),
            toCol: Math.ceil(visibleToX),
        });
    }, [offset, scale, image, canvasSize, setVisibleArea]);

    useEffect(() => {
        drawImage();
    }, [image, scale, offset, newBoxId, voxels, showVoxels, canvasSize, hoverCoords, hoveredCorner, resizingState]);

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!scale || !offset || !heatmapData) return;
        const rect = canvasRef.current!.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        if (e.button === 0 && editingMode) {
            // Left mouse button
            if (hoveredCorner) {
                const { bboxId, corner } = hoveredCorner;
                setVoxelSelected(bboxId);
                setResizingState({ bboxId, corner });
            } else if (hoveredSide) {
                const { bboxId, side } = hoveredSide;
                setVoxelSelected(bboxId);
                // Treat side as corresponding midpoint handle
                let cornerEquivalent: HoverCorner = sideToHoverCorner(side);
                setResizingState({ bboxId, corner: cornerEquivalent });
            } else {
                const cell = getHeatmapCell(mouseX, mouseY, offset, scale, heatmapData);
                if (cell) {
                    deselectVoxels();
                    const newId = getNextBoxId();
                    updateVoxel({
                        id: newId,
                        start: cell,
                        end: cell,
                        type: "rectangle",
                        selected: true,
                    });
                    setNewBoxId(newId);
                }
            }
        } else if (e.button === 2) {
            // Right mouse button: Start panning
            isPanning.current = true;
            panStart.current = {
                x: e.clientX - offset.x,
                y: e.clientY - offset.y,
            };
        }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!canvasRef.current || !scale || !offset || !heatmapData || !image) return;
        const rect = canvasRef.current.getBoundingClientRect();
        const { canvasX, canvasY, mouseX, mouseY } = calculateMousePosition(e, rect, offset, scale);

        // Update hover coordinates
        if (isWithinImageBound(canvasX, canvasY, imageWidth, imageHeight)) {
            setHoverCoords({
                x: Math.floor(canvasX),
                y: Math.floor(canvasY),
            });
        }

        // Handle resizing
        if (resizingState && editingMode) {
            const { bboxId, corner } = resizingState;
            const bboxIndex = voxels.findIndex((bbox) => bbox.id === bboxId);
            if (bboxIndex === -1) {
                setResizingState(null);
                return;
            }
            const bbox = voxels[bboxIndex];

            const cell = getHeatmapCell(mouseX, mouseY, offset, scale, heatmapData);
            if (cell) {
                const updatedBBox = structuredClone(bbox);
                // Resizing logic for both rectangle and longVoxel now similar
                updateBoxByCorner(corner, updatedBBox, cell);
                updateVoxel({ ...updatedBBox, selected: true });
            }
            setHoveredBBox(null);
            return;
        }

        // Handle panning
        if (isPanning.current) {
            const newOffsetX = e.clientX - panStart.current.x;
            const newOffsetY = e.clientY - panStart.current.y;

            const scaledWidth = image.width * scale.x || 0;
            const scaledHeight = image.height * scale.y || 0;

            const clampedX = Math.max(Math.min(newOffsetX, 0), canvasSize.width - scaledWidth);
            const clampedY = Math.max(Math.min(newOffsetY, 0), canvasSize.height - scaledHeight);

            onOffsetChange({ x: clampedX, y: clampedY });
            setHoveredBBox(null);
            return;
        }

        // Handle bounding box drawing
        if (newBoxId && editingMode) {
            const cell = getHeatmapCell(mouseX, mouseY, offset, scale, heatmapData);
            if (cell) {
                const currentVoxel = voxels.find((bbox) => bbox.id === newBoxId);
                if (!currentVoxel) return;
                updateVoxel({ ...currentVoxel, end: cell });
            }
        }

        // Detect hover near bounding boxes
        const hoverThreshold = 5; // Pixels around the corner or side
        let cornerHovered = false;
        let sideHovered = false;

        const calculateStatsAndSetHoveredBBox = (bbox: BoundingBox, position: { x: number; y: number }) => {
            setHoveredBBox({
                position,
                stats: calculateBoundingBoxStatistics(bbox.start, bbox.end, {
                    width: heatmapData.width,
                    height: heatmapData.height,
                    start_freq: heatmapData.start_freq,
                    end_freq: heatmapData.end_freq,
                    start_time: heatmapData.start_time,
                    end_time: heatmapData.end_time,
                    data: imageData,
                }),
            });
        };

        const handleHover = (
            bbox: BoundingBox,
            mouseX: number,
            mouseY: number,
            x: number,
            y: number,
            cornerName: HoverCorner
        ) => {
            if (Math.abs(mouseX - x) <= hoverThreshold && Math.abs(mouseY - y) <= hoverThreshold) {
                setHoveredCorner({ bboxId: bbox.id, corner: cornerName });
                cornerHovered = true;
                calculateStatsAndSetHoveredBBox(bbox, { x, y });
                return true;
            }
            return false;
        };

        setHoveredSide(null);

        voxels.forEach((bbox) => {
            const bboxStartX = bbox.start.col * scale.x + offset.x;
            const bboxStartY = bbox.start.row * scale.y + offset.y;
            const bboxEndX = bbox.end.col * scale.x + offset.x;
            const bboxEndY = bbox.end.row * scale.y + offset.y;

            if (bbox.type === "longVoxel") {
                const midX = (bboxStartX + bboxEndX) / 2;
                const midY = (bboxStartY + bboxEndY) / 2;

                // Check left and right handles (corners for longVoxel)
                if (handleHover(bbox, mouseX, mouseY, bboxStartX, midY, "left")) return;
                if (handleHover(bbox, mouseX, mouseY, bboxEndX, midY, "right")) return;

                // Add top and bottom sides as well as their midpoints
                if (handleHover(bbox, mouseX, mouseY, midX, bboxStartY, "top-mid")) return;
                if (handleHover(bbox, mouseX, mouseY, midX, bboxEndY, "bottom-mid")) return;

                // If not corners/midpoints, check sides for longVoxel
                if (!cornerHovered) {
                    // top side
                    if (
                        Math.abs(mouseY - bboxStartY) <= hoverThreshold &&
                        mouseX > bboxStartX + hoverThreshold &&
                        mouseX < bboxEndX - hoverThreshold
                    ) {
                        setHoveredSide({ bboxId: bbox.id, side: "top" });
                        sideHovered = true;
                        return;
                    }
                    // bottom side
                    if (
                        Math.abs(mouseY - bboxEndY) <= hoverThreshold &&
                        mouseX > bboxStartX + hoverThreshold &&
                        mouseX < bboxEndX - hoverThreshold
                    ) {
                        setHoveredSide({ bboxId: bbox.id, side: "bottom" });
                        sideHovered = true;
                        return;
                    }
                    // left side
                    if (
                        Math.abs(mouseX - bboxStartX) <= hoverThreshold &&
                        mouseY > bboxStartY + hoverThreshold &&
                        mouseY < bboxEndY - hoverThreshold
                    ) {
                        setHoveredSide({ bboxId: bbox.id, side: "left" });
                        sideHovered = true;
                        return;
                    }
                    // right side
                    if (
                        Math.abs(mouseX - bboxEndX) <= hoverThreshold &&
                        mouseY > bboxStartY + hoverThreshold &&
                        mouseY < bboxEndY - hoverThreshold
                    ) {
                        setHoveredSide({ bboxId: bbox.id, side: "right" });
                        sideHovered = true;
                        return;
                    }
                }
            } else {
                // Rectangle logic as before
                if (handleHover(bbox, mouseX, mouseY, bboxStartX, bboxStartY, "top-left")) return;
                if (handleHover(bbox, mouseX, mouseY, bboxEndX, bboxEndY, "bottom-right")) return;

                const midX = (bboxStartX + bboxEndX) / 2;
                const midY = (bboxStartY + bboxEndY) / 2;

                if (handleHover(bbox, mouseX, mouseY, midX, bboxStartY, "top-mid")) return;
                if (handleHover(bbox, mouseX, mouseY, midX, bboxEndY, "bottom-mid")) return;
                if (handleHover(bbox, mouseX, mouseY, bboxStartX, midY, "left-mid")) return;
                if (handleHover(bbox, mouseX, mouseY, bboxEndX, midY, "right-mid")) return;

                // Check sides for rectangle
                if (!cornerHovered) {
                    // top side
                    if (
                        Math.abs(mouseY - bboxStartY) <= hoverThreshold &&
                        mouseX > bboxStartX + hoverThreshold &&
                        mouseX < bboxEndX - hoverThreshold
                    ) {
                        setHoveredSide({ bboxId: bbox.id, side: "top" });
                        sideHovered = true;
                        return;
                    }
                    // bottom side
                    if (
                        Math.abs(mouseY - bboxEndY) <= hoverThreshold &&
                        mouseX > bboxStartX + hoverThreshold &&
                        mouseX < bboxEndX - hoverThreshold
                    ) {
                        setHoveredSide({ bboxId: bbox.id, side: "bottom" });
                        sideHovered = true;
                        return;
                    }
                    // left side
                    if (
                        Math.abs(mouseX - bboxStartX) <= hoverThreshold &&
                        mouseY > bboxStartY + hoverThreshold &&
                        mouseY < bboxEndY - hoverThreshold
                    ) {
                        setHoveredSide({ bboxId: bbox.id, side: "left" });
                        sideHovered = true;
                        return;
                    }
                    // right side
                    if (
                        Math.abs(mouseX - bboxEndX) <= hoverThreshold &&
                        mouseY > bboxStartY + hoverThreshold &&
                        mouseY < bboxEndY - hoverThreshold
                    ) {
                        setHoveredSide({ bboxId: bbox.id, side: "right" });
                        sideHovered = true;
                        return;
                    }
                }
            }
        });

        // Update cursor and tooltip based on what is hovered
        if (cornerHovered && showVoxels) {
            // Stats and highlight are already shown in handleHover
            if (canvasRef.current && hoveredCorner) {
                switch (hoveredCorner.corner) {
                    case "top-mid":
                    case "bottom-mid":
                        if (editingMode) {
                            canvasRef.current.style.cursor = "ns-resize";
                        }
                        break;
                    case "left-mid":
                    case "right-mid":
                    case "left":
                    case "right":
                        if (editingMode) {
                            canvasRef.current.style.cursor = "ew-resize";
                        }
                        break;
                    default:
                        if (editingMode) {
                            canvasRef.current.style.cursor = "nwse-resize";
                        }
                        break;
                }
            }
        } else if (sideHovered) {
            // If hovering over a side (no corners/midpoints)
            setHoveredCorner(null);
            setHoveredBBox(null);
            if (canvasRef.current && hoveredSide) {
                // No stats, no highlight, just resizing cursor
                if ((hoveredSide.side === "top" || hoveredSide.side === "bottom") && editingMode) {
                    canvasRef.current.style.cursor = "ns-resize";
                } else {
                    if (editingMode) {
                        canvasRef.current.style.cursor = "ew-resize";
                    }
                }
            }
        } else {
            // Not hovering over corner or side
            setHoveredCorner(null);
            setHoveredSide(null);
            setHoveredBBox(null);
            if (canvasRef.current) {
                canvasRef.current.style.cursor = "default";
            }
        }
    };

    const finishNewBox = () => {
        const newVoxel = voxels.find((bbox) => bbox.id === newBoxId);
        if (newVoxel && (newVoxel.start.col === newVoxel.end.col || newVoxel.start.row === newVoxel.end.row)) {
            deleteSelectedVoxel();
        } else {
            persistVoxels();
        }
        setNewBoxId(null);
    };

    const finishResizing = () => {
        persistVoxels();
        setResizingState(null);
    };

    const handleMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (e.button === 0 && editingMode) {
            // Left mouse button
            if (resizingState) {
                finishResizing();
            }
            if (newBoxId) {
                finishNewBox();
            }
        }
        if (e.button === 2) {
            isPanning.current = false;
        }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.key === "z") {
            if (editingMode) {
                deselectVoxels();
                setResizingState(null);
                setNewBoxId(null);
                undo();
            } else {
                window.alert("Undo is only available in editing mode.");
            }
        }
        if (e.key === "Delete" && editingMode) {
            deleteSelectedVoxel();
            setResizingState(null);
            persistVoxels();
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [showVoxels, editingMode, hoveredCorner]);

    const handleWheel = (e: React.WheelEvent<HTMLCanvasElement>) => {
        if (!scale || !offset) return;

        const { offsetX, offsetY } = e.nativeEvent;

        const minScaleX = canvasSize.width / imageWidth;
        const minScaleY = canvasSize.height / imageHeight;

        let newScaleX = scale.x;
        let newScaleY = scale.y;

        if (e.ctrlKey) {
            // Vertical zoom
            newScaleY = Math.max(Math.min(scale.y - e.deltaY * 0.001, MAX_ZOOM), minScaleY);
        } else if (e.shiftKey) {
            // Horizontal zoom
            newScaleX = Math.max(Math.min(scale.x - (e.deltaX + e.deltaY) * 0.001, MAX_ZOOM), minScaleX);
        } else {
            // Uniform zoom
            newScaleX = Math.max(Math.min(scale.x - e.deltaY * 0.001, MAX_ZOOM), minScaleX);
            newScaleY = Math.max(Math.min(scale.y - e.deltaY * 0.001, MAX_ZOOM), minScaleY);
        }

        const scaleRatioX = newScaleX / scale.x;
        const scaleRatioY = newScaleY / scale.y;

        const newOffsetX = offset.x - (offsetX - offset.x) * (scaleRatioX - 1);
        const newOffsetY = offset.y - (offsetY - offset.y) * (scaleRatioY - 1);

        onScaleChange({ x: newScaleX, y: newScaleY });
        onOffsetChange({
            x: Math.max(Math.min(newOffsetX, 0), canvasSize.width - imageWidth * newScaleX),
            y: Math.max(Math.min(newOffsetY, 0), canvasSize.height - imageHeight * newScaleY),
        });
    };

    function handleMouseLeave() {
        if (resizingState) {
            finishResizing();
        }
        if (newBoxId) {
            finishNewBox();
        }
        isPanning.current = false;
    }

    return (
        <div
            className="main-canvas-container"
            onContextMenu={(e) => e.preventDefault()}
        >
            <canvas
                ref={canvasRef}
                className="main-canvas"
                onMouseMove={handleMouseMove}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                onWheel={handleWheel}
            />
            {hoveredBBox && (
                <div
                    style={{
                        left: hoveredBBox.position.x + 10,
                        top: hoveredBBox.position.y + 10,
                    }}
                    className="hovered-bbox-tooltip"
                >
                    <div>Freq: {hoveredBBox.stats.freqRange}</div>
                    <div>Time: {hoveredBBox.stats.timeRange}</div>
                    <div>Max: {hoveredBBox.stats.max}</div>
                    <div>Avg: {hoveredBBox.stats.average}</div>
                </div>
            )}
        </div>
    );
}
export default MainCanvas;
