// Copyright (C) 2024 Xtremis, All rights reserved

import { create } from "zustand";
import { API_URL, HISTORY_SIZE } from "./constants";

interface CacheStore {
    selectedAntenna: number;
    selectedAggregation: string;
    setSelectedAntenna: (antenna: number) => void;
    setAggregation: (aggregation: string) => void;
    error: string;
    setError: (error: string) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    heatmapData: HeatmapData | null;
    setHeatmapData: (heatmapData: HeatmapData | null) => void;
    updateHeatmapData: (data: Float32Array, receiver: number, feature: string) => void;
    showVoxels: boolean;
    setShowVoxels: (showVoxels: boolean) => void;
    hoverCoords: Coords;
    setHoverCoords: (coords: Coords) => void;
    visibleArea: VisibleArea;
    setVisibleArea: (area: VisibleArea) => void;

    editingMode: boolean;
    setEditingMode: (mode: boolean) => void;

    canvasSize: CanvasSize; // use this only for drawing the hAxis, vAxis, LongVoxel and histogram drawing
    setCanvasSize: (size: CanvasSize) => void;

    // Add scale and offset state for image from the main canvas
    scale: Scale;
    offset: Offset;
    scaleOffsetInitialized: boolean;
    setScale: (scale: Scale) => void;
    setOffset: (offset: Offset) => void;
    setScaleOffsetInitialized: (initialized: boolean) => void;

    nextBBoxId: number;
    getNextBoxId: () => number;
    voxels: BoundingBox[];
    voxelHistory: BoundingBox[][];
    undo: () => void;
    persistVoxels: () => void;
    updateVoxel: (boundingBox: BoundingBox) => void;
    deleteSelectedVoxel: () => void;
    initVoxels: (boundingBoxes: BoundingBox[]) => void;
    deselectVoxels: () => void;
    setVoxelSelected(id: number): void;

    reset: () => void;

    selectedPath: string;
    setSelectedPath: (path: string) => void;
}

export const useCacheStore = create<CacheStore>((set, get) => ({
    selectedAntenna: 0,
    selectedAggregation: "avg",
    error: "",
    loading: true,
    heatmapData: null,
    showVoxels: true,
    setSelectedAntenna: (antenna) => set({ selectedAntenna: antenna }),
    setAggregation: (aggregation) => set({ selectedAggregation: aggregation }),
    setError: (error) => set({ error }),
    setLoading: (loading) => set({ loading }),
    setHeatmapData: (heatmapData) => set({ heatmapData }),
    updateHeatmapData: (data: Float32Array, receiver: number, feature: string) => {
        const hData = get().heatmapData;
        if (!hData || !hData.images || !hData.images[receiver] || !(feature in hData.images[receiver])) {
            return;
        }
        hData.images[receiver][feature] = data;
        set({ heatmapData: { ...hData } });
    },
    setShowVoxels: (showVoxels) => set({ showVoxels }),
    hoverCoords: { x: 0, y: 0 },
    setHoverCoords: (coords) => set({ hoverCoords: coords }),
    visibleArea: { fromRow: 0, toRow: 0, fromCol: 0, toCol: 0 },
    setVisibleArea: (area) => set({ visibleArea: area }),
    editingMode: true,
    setEditingMode: (mode: boolean) => set({ editingMode: mode }),

    canvasSize: { width: 0, height: 0 },
    setCanvasSize: (size: CanvasSize) => set({ canvasSize: size }),

    scale: { x: 1, y: 1 },
    offset: { x: 0, y: 0 },
    scaleOffsetInitialized: false,
    setScale: (scale: Scale) => set({ scale }),
    setOffset: (offset: Offset) => set({ offset }),
    setScaleOffsetInitialized: (initialized) => set({ scaleOffsetInitialized: initialized }),

    voxels: [],
    voxelHistory: [],
    undo: () => {
        const { voxelHistory } = get();
        if (voxelHistory.length > 1) {
            voxelHistory.pop();
            const actualVoxels = voxelHistory.pop();
            set({ voxels: actualVoxels });
            get().persistVoxels();
        } else {
            window.alert("No more history to undo");
        }
    },
    persistVoxels: () => {
        const voxels = get().voxels;
        const newHist = get().voxelHistory.slice(-HISTORY_SIZE);
        newHist.push(structuredClone(get().voxels));
        set({ voxelHistory: newHist });
        fetch(`${API_URL}/update_voxels`, {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                voxels: voxels.map((bb) => {
                    return {
                        startRow: Math.min(bb.start.row, bb.end.row),
                        endRow: Math.max(bb.start.row, bb.end.row),
                        startCol: Math.min(bb.start.col, bb.end.col),
                        endCol: Math.max(bb.start.col, bb.end.col),
                    };
                }),
                path: get().selectedPath,
            }),
        })
            .catch((error: string) => {
                console.error("Error updating voxels", error);
            })
            .finally(() => {
                set({ voxels });
            });
    },
    updateVoxel: (voxel) => {
        const voxels = get().voxels.map((v) => {
            return v.id === voxel.id ? voxel : v;
        });
        if (voxels.find((v) => v.id === voxel.id) === undefined) {
            voxels.push(voxel);
        }
        set({ voxels });
    },
    deleteSelectedVoxel: () => {
        const voxels = get().voxels.filter((voxel) => !voxel.selected);
        set({ voxels });
    },
    initVoxels: (voxels) => {
        set({ voxels });
        set({ voxelHistory: [structuredClone(voxels)] });
    },
    deselectVoxels: () => set({ voxels: get().voxels.map((voxel) => ({ ...voxel, selected: false })) }),
    setVoxelSelected: (id) => {
        const voxels = get().voxels.map((voxel) => {
            if (voxel.id === id) {
                return { ...voxel, selected: true };
            }
            return { ...voxel, selected: false };
        });
        set({ voxels });
    },

    nextBBoxId: 0,
    getNextBoxId: () => {
        const newValue = get().nextBBoxId + 1;
        set({ nextBBoxId: newValue });
        return newValue;
    },

    reset: () =>
        set({
            selectedAntenna: 0,
            selectedAggregation: "avg",
            error: "",
            loading: true,
            heatmapData: null,
            showVoxels: true,
            hoverCoords: { x: 0, y: 0 },
            visibleArea: { fromRow: 0, toRow: 0, fromCol: 0, toCol: 0 },
            canvasSize: { width: 0, height: 0 },
            scale: { x: 1, y: 1 },
            offset: { x: 0, y: 0 },
            scaleOffsetInitialized: false,
            voxels: [],
            nextBBoxId: 0,
            editingMode: true,
            voxelHistory: [],
        }),
    selectedPath: "",
    setSelectedPath: (path) => set({ selectedPath: path }),
}));
