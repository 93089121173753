// Copyright (C) 2024 Xtremis, All rights reserved

import {
    blue,
    HALF_HANDLE_SIZE,
    HANDLE_SIZE,
    hertz_divider,
    hertz_unit,
    red,
    red_box_background,
    axis_cross_line_color
} from "./constants";

export const getHeatmapCell = (
    x: number,
    y: number,
    offset: { x: number; y: number },
    scale: { x: number; y: number },
    heatmapData: { width: number; height: number }
) => {
    const col = (x - offset.x) / scale.x;
    const row = (y - offset.y) / scale.y;
    if (col >= 0 && col < heatmapData.width && row >= 0 && row < heatmapData.height) {
        return { col, row };
    }
    return null;
};

export const drawBoundingBox = (
    ctx: CanvasRenderingContext2D,
    start: { col: number; row: number },
    end: { col: number; row: number },
    scale: Scale,
    offset: Offset,
    color: string
) => {
    const startX = Math.min(start.col, end.col) * scale.x + offset.x;
    const startY = Math.min(start.row, end.row) * scale.y + offset.y;
    const endX = Math.max(start.col, end.col) * scale.x + offset.x;
    const endY = Math.max(start.row, end.row) * scale.y + offset.y;

    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.strokeRect(startX, startY, endX - startX, endY - startY);

    ctx.fillStyle = red_box_background;
    ctx.fillRect(startX, startY, endX - startX, endY - startY);
};

export const calculateBoundingBoxStatistics = (
    start: { col: number; row: number },
    end: { col: number; row: number },
    heatmapData: {
        width: number;
        height: number;
        start_freq: number;
        end_freq: number;
        start_time: number;
        end_time: number;
        data: Float32Array;
    }
) => {
    const fromCol = Math.floor(Math.min(start.col, end.col));
    const toCol = Math.floor(Math.max(start.col, end.col));
    const fromRow = Math.floor(Math.min(start.row, end.row));
    const toRow = Math.floor(Math.max(start.row, end.row));

    const freqRangeStart =
        heatmapData.start_freq + (fromCol / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq);
    const freqRangeEnd =
        heatmapData.start_freq + (toCol / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq);

    const timeRangeStart =
        heatmapData.start_time + (fromRow / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time);
    const timeRangeEnd =
        heatmapData.start_time + (toRow / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time);

    let sum = 0;
    let max = -Infinity;
    let count = 0;

    for (let row = fromRow; row <= toRow; row++) {
        for (let col = fromCol; col <= toCol; col++) {
            const index = row * heatmapData.width + col;
            const value = heatmapData.data[index];
            sum += value;
            if (value > max) max = value;
            count++;
        }
    }

    const avg = sum / count;

    return {
        freqRange: `${(freqRangeStart / hertz_divider).toFixed(2)} ${hertz_unit} - ${(freqRangeEnd / hertz_divider).toFixed(2)} ${hertz_unit}`,
        timeRange: `${timeRangeStart.toFixed(2)} s - ${timeRangeEnd.toFixed(2)} s`,
        average: avg.toFixed(2),
        max: max.toFixed(2),
    };
};

export const drawVoxels = (
    ctx: CanvasRenderingContext2D,
    bbox: BoundingBox,
    scale: Scale,
    offset: Offset,
    centerY: number,
    resizingState: {
        bboxId: number;
        corner: HoverCorner;
    } | null,
    hoveredCorner: {
        bboxId: number;
        corner: HoverCorner;
    } | null
) => {
    const isResizing = resizingState && resizingState.bboxId === bbox.id;

    let color = red;
    if (bbox.selected || isResizing) {
        color = blue;
    }

    drawBoundingBox(ctx, bbox.start, bbox.end, scale, offset, color);

    const bboxStartX = bbox.start.col * scale.x + offset.x;
    const bboxEndX = bbox.end.col * scale.x + offset.x;
    const bboxStartY = bbox.start.row * scale.y + offset.y;
    const bboxEndY = bbox.end.row * scale.y + offset.y;

    if (bbox.type === "longVoxel") {
        const fillStyle = bbox.selected || isResizing ? blue : red;
        drawLongVoxel(ctx, fillStyle, centerY, bboxStartX, bboxEndX);
    } else if (bbox.type === "rectangle") {
        drawLRectangle(ctx, isResizing, bbox.selected, bbox.id, bboxStartX, bboxStartY, bboxEndX, bboxEndY, hoveredCorner);
    }
};

export const drawCrossLines = (
    ctx: CanvasRenderingContext2D,
    image: HTMLImageElement,
    editingMode: boolean,
    hoverCoords: Coords,
    scale: Scale,
    clampedOffsetX: number,
    clampedOffsetY: number
): void => {
    if (!hoverCoords) {
        return;
    }

    const scaledWidth = image.width * scale.x;
    if (hoverCoords.y >= 0 && hoverCoords.y < image.height) {
        const hoverY = hoverCoords.y * scale.y + clampedOffsetY;
        drawHorizontalLine(ctx, hoverY, clampedOffsetX, scaledWidth);
    }

    if (!editingMode) {
        return;
    }

    const scaledHeight = image.height * scale.y;
    if (hoverCoords.x >= 0 && hoverCoords.x < image.width) {
        const hoverX = hoverCoords.x * scale.x + clampedOffsetX;
        drawVerticalLine(ctx, hoverX, clampedOffsetY, scaledHeight);
    }
};

const drawHorizontalLine = (ctx: CanvasRenderingContext2D, y: number, offsetX: number, width: number): void => {
    ctx.strokeStyle = axis_cross_line_color;
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(offsetX, y);
    ctx.lineTo(offsetX + width, y);
    ctx.stroke();
};

const drawVerticalLine = (ctx: CanvasRenderingContext2D, x: number, offsetY: number, height: number): void => {
    ctx.strokeStyle = axis_cross_line_color;
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(x, offsetY);
    ctx.lineTo(x, offsetY + height);
    ctx.stroke();
};

const drawLongVoxel = (
    ctx: CanvasRenderingContext2D,
    fillStyle: string,
    centerY: number,
    bboxStartX: number,
    bboxEndX: number
): void => {
    ctx.fillStyle = fillStyle;
    ctx.fillRect(bboxStartX - HALF_HANDLE_SIZE, centerY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);
    ctx.fillRect(bboxEndX - HALF_HANDLE_SIZE, centerY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);
};

const drawLRectangle = (
    ctx: CanvasRenderingContext2D,
    isResizing: boolean | null,
    selected: boolean,
    boxId: number,
    bboxStartX: number,
    bboxStartY: number,
    bboxEndX: number,
    bboxEndY: number,
    hoveredCorner: {
        bboxId: number;
        corner: HoverCorner;
    } | null
): void => {
    // Corners
    ctx.fillStyle = isActive(hoveredCorner, boxId, selected, isResizing, "top-left") ? blue : red;
    ctx.fillRect(bboxStartX - HALF_HANDLE_SIZE, bboxStartY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hoveredCorner, boxId, selected, isResizing, "bottom-right") ? blue : red;
    ctx.fillRect(bboxEndX - HALF_HANDLE_SIZE, bboxEndY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    // Midpoints
    const midX = (bboxStartX + bboxEndX) / 2;
    const midY = (bboxStartY + bboxEndY) / 2;

    ctx.fillStyle = isActive(hoveredCorner, boxId, selected, isResizing, "top-mid") ? blue : red;
    ctx.fillRect(midX - HALF_HANDLE_SIZE, bboxStartY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hoveredCorner, boxId, selected, isResizing, "bottom-mid") ? blue : red;
    ctx.fillRect(midX - HALF_HANDLE_SIZE, bboxEndY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hoveredCorner, boxId, selected, isResizing, "left-mid") ? blue : red;
    ctx.fillRect(bboxStartX - HALF_HANDLE_SIZE, midY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hoveredCorner, boxId, selected, isResizing, "right-mid") ? blue : red;
    ctx.fillRect(bboxEndX - HALF_HANDLE_SIZE, midY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);
};

const isActive = (
    hoveredCorner: {
        bboxId: number;
        corner: HoverCorner;
    } | null,
    boxId: number,
    selected: boolean,
    isResizing: boolean | null,
    controlPointName: string
): boolean | null => {
    return (
        selected ||
        isResizing ||
        (hoveredCorner && hoveredCorner.bboxId === boxId && hoveredCorner.corner === controlPointName)
    );
};
