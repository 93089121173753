// Copyright (C) 2024 Xtremis, All rights reserved

import { API_URL } from "./constants";

export function formatPath(path: string, maxLength: number): string {
    const regex = new RegExp(`.{1,${maxLength}}`, "g");
    const segments = path.match(regex) || [path];
    return segments.join("\n");
}

export function normalizeData(data: Float32Array): Float32Array {
    const { min, max } = data.reduce(
        (acc, value) => ({
            min: Math.min(acc.min, value),
            max: Math.max(acc.max, value),
        }),
        { min: Infinity, max: -Infinity }
    );
    return data.map((value) => (value - min) / (max - min));
}

interface ColorStop {
    value: number;
    color: number;
}

const colorStops: ColorStop[] = [
    { value: 0.0, color: 0x2860a2 }, // Blue
    { value: 0.33, color: 0x18a060 }, // Greenish
    { value: 0.66, color: 0x19a25d }, // Similar to above
    { value: 1.0, color: 0x08d827 }, // Bright Green
];

const extractRGB = (color: number) => ({
    r: (color >> 16) & 0xff,
    g: (color >> 8) & 0xff,
    b: color & 0xff,
});

export const getColorFromNormalizedValue = (normalizedValue: number): number => {
    let startStop = colorStops[0];
    let endStop = colorStops[colorStops.length - 1];

    for (let i = 0; i < colorStops.length - 1; i++) {
        if (normalizedValue >= colorStops[i].value && normalizedValue <= colorStops[i + 1].value) {
            startStop = colorStops[i];
            endStop = colorStops[i + 1];
            break;
        }
    }

    const range = endStop.value - startStop.value;
    const factor = (normalizedValue - startStop.value) / range;

    const startColor = extractRGB(startStop.color);
    const endColor = extractRGB(endStop.color);

    const r = Math.round(startColor.r + factor * (endColor.r - startColor.r));
    const g = Math.round(startColor.g + factor * (endColor.g - startColor.g));
    const b = Math.round(startColor.b + factor * (endColor.b - startColor.b));

    return (r << 16) + (g << 8) + b;
};

export function createBluishImage(data: Float32Array, width: number, height: number): ImageData {
    const imageData = new ImageData(width, height);
    for (let i = 0; i < data.length; i++) {
        const value = data[i];
        const color = getColorFromNormalizedValue(value);

        const { r, g, b } = extractRGB(color); // Use the utility function

        const idx = i * 4; // RGBA channels
        imageData.data[idx] = r; // Red
        imageData.data[idx + 1] = g; // Green
        imageData.data[idx + 2] = b; // Blue
        imageData.data[idx + 3] = 255; // Alpha
    }
    return imageData;
}

export function imageDataToBlob(imageData: ImageData): Promise<Blob> {
    const canvas = document.createElement("canvas");
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
        throw new Error("Could not get 2D context from canvas");
    }
    ctx.putImageData(imageData, 0, 0);

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            if (blob) {
                resolve(blob);
            } else {
                console.log("Failed to create Blob from canvas");
            }
        }, "image/png");
    });
}

export const fetchMatrix = async (path: string): Promise<ResponseHeatmapData> => {
    const params = new URLSearchParams({ path });
    const data = await fetch(`${API_URL}/info?${params.toString()}`, { credentials: "include" });
    const info: {
        height: number;
        width: number;
        features: string[];
        receivers: number;
        voxels: Voxels;
        minValue: number;
        maxValue: number;
        start_time: number;
        end_time: number;
        start_freq: number;
        end_freq: number;
        sampling_rate: number;
    } = await data.json();

    let response: ResponseHeatmapData = {
        width: info.width,
        height: info.height,
        voxels: info.voxels,
        minValue: info.minValue,
        maxValue: info.maxValue,
        start_time: info.start_time,
        end_time: info.end_time,
        start_freq: info.start_freq,
        end_freq: info.end_freq,
        images: {},
        sampling_rate: info.sampling_rate,
    };

    for (let i = 0; i < info.receivers; i++) {
        if (!response.images[i]) {
            response.images[i] = {};
        }
        for (const feature of info.features) {
            response.images[i][feature] = null;
        }
    }
    const urlParams = new URLSearchParams({ path, receiver: "0", feature: info.features[0] });
    const img = await fetch(`${API_URL}/image?${urlParams.toString()}`, { credentials: "include" });
    response.images[0][info.features[0]] = new Float32Array(await img.arrayBuffer());

    return response;
};

export const fetchRestMatrix = async (
    path: string,
    receivers: number,
    features: string[],
    updateHeatmapData: (array: Float32Array, receiver: number, aggregation: string) => void
) => {
    for (let i = 0; i < receivers; i++) {
        for (const feature of features) {
            if (i === 0 && feature === features[0]) continue;
            const urlParams = new URLSearchParams({ path, receiver: i.toString(), feature: feature });
            const img = await fetch(`${API_URL}/image?${urlParams.toString()}`, { credentials: "include" });
            updateHeatmapData(new Float32Array(await img.arrayBuffer()), i, feature);
        }
    }
};

export const sideToHoverCorner = (side: Directions): HoverCorner => {
    let cornerEquivalent: HoverCorner;
    switch (side) {
        case "top":
            cornerEquivalent = "top-mid";
            break;
        case "bottom":
            cornerEquivalent = "bottom-mid";
            break;
        case "left":
            cornerEquivalent = "left-mid";
            break;
        case "right":
            cornerEquivalent = "right-mid";
            break;
    }

    return cornerEquivalent;
};

export const updateBoxByCorner = (
    corner: HoverCorner,
    updatedBBox: BoundingBox,
    cell: { col: number; row: number }
): void => {
    switch (corner) {
        case "left":
        case "left-mid":
            updatedBBox.start.col = Math.min(cell.col, updatedBBox.end.col);
            break;
        case "right":
        case "right-mid":
            updatedBBox.end.col = Math.max(cell.col, updatedBBox.start.col);
            break;
        case "top-mid":
            updatedBBox.start.row = Math.min(cell.row, updatedBBox.end.row);
            break;
        case "bottom-mid":
            updatedBBox.end.row = Math.max(cell.row, updatedBBox.start.row);
            break;
        case "top-left":
            updatedBBox.start = cell;
            break;
        case "bottom-right":
            updatedBBox.end = cell;
            break;
    }
};

export const calculateMousePosition = (
    e: React.MouseEvent<HTMLCanvasElement>,
    rect: DOMRect,
    offset: Offset,
    scale: Scale
) => {
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    return {
        canvasX: (mouseX - offset.x) / scale.x,
        canvasY: (mouseY - offset.y) / scale.y,
        mouseX,
        mouseY,
    };
};

export const isWithinImageBound = (canvasX: number, canvasY: number, imageW: number, imageH: number): boolean => {
    return canvasX >= 0 && canvasX < imageW && canvasY >= 0 && canvasY < imageH;
};
