// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useEffect, useState } from "react";
import "./HelpMenu.css";
import { API_URL } from "../utils/constants";

interface HelpMenuProps {
    onClose: () => void;
    onLogout: () => void;
}

const HelpMenu: React.FC<HelpMenuProps> = ({ onClose, onLogout }) => {
    const [hasLogout, setHasLogout] = useState(false);
    useEffect(() => {
        fetch(`${API_URL}/hasLogout`, { credentials: "include" })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setHasLogout(data.hasLogout);
            })
            .catch((error) => {
                console.error("Error checking session:", error);
            });
    }, []);

    return (
        <div className="help-menu">
            <h3>Viewer functions</h3>
            <ul>
                <li>
                    <b>Select recording: </b> Hamburger menu on the left
                </li>
                <li>
                    <b>Zoom: </b>Scroll with mouse
                </li>
                <li>
                    <b>Vertical zoom: </b>Ctrl + zoom
                </li>
                <li>
                    <b>Horizontal zoom: </b>Shift + zoom
                </li>
                <li>
                    <b>Move the image on the map: </b>Hold down right click and move the mouse
                </li>
                <li>
                    <b>Switch between receivers and values: </b>Choose either the avg or the max of one receiver
                </li>
            </ul>
            <h3>Tagger functions</h3>
            <ul>
                <li>
                    <b>Edit mode: </b>Switch edit mode on with the button to draw or edit bounding boxes
                </li>
                <li>
                    <b>Draw voxel: </b>Hold down left click and move the mouse
                </li>
                <li>
                    <b>Draw long voxel: </b>Left click and move mouse in the bar below the PSD diagram and above the
                    waterfall plot
                </li>
                <li>
                    <b>Select voxel for editing: </b>Left click on the editing point
                </li>
                <li>
                    <b>Delete selected voxel: </b>Delete button on the keyboard
                </li>
                <li>
                    <b>Undo: </b>CTRL + Z
                </li>
                <li>
                    <b>Save: </b>Voxels are automatically saved
                </li>
            </ul>
            <button onClick={onClose}>Close</button>
            {hasLogout && (
                <button onClick={onLogout} className="logout-button">
                    Logout
                </button>
            )}
        </div>
    );
};

export default HelpMenu;
