// Copyright (C) 2024 Xtremis, All rights reserved
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

/**
 * The root element of the application is initialized here.
 * This file renders the root `App` component inside a `StrictMode` wrapper,
 * which helps identify potential issues during development by activating additional checks.
 */
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement, // The root element where the app is rendered
);

root.render(
    <React.StrictMode>
        <App /> {/* Main application component */}
    </React.StrictMode>,
);
