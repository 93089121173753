// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useRef, useEffect, useState } from "react";
import { hertz_divider, hertz_unit, HISTOGRAM_HEIGHT, MAIN_CANVAS_AXIS_SIZE, MIN_BIN_WIDTH } from "../utils/constants";
import { useCacheStore } from "../utils/store";
import "./Histogram.css"

const Histogram: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [data, setData] = useState<number[]>([]);
    const { heatmapData, selectedAntenna, selectedAggregation, visibleArea, hoverCoords, canvasSize } = useCacheStore();
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        if (!heatmapData) return;

        setData(
            Array.from(
                heatmapData.images[selectedAntenna][selectedAggregation]!.slice(
                    hoverCoords.y * heatmapData.width + visibleArea.fromCol,
                    hoverCoords.y * heatmapData.width + visibleArea.toCol,
                ),
            ),
        );
    }, [heatmapData, selectedAggregation, selectedAntenna, visibleArea, hoverCoords]);

    const aggregateData = (data: number[], drawableWidth: number): number[] => {
        const maxBins = Math.floor(drawableWidth / MIN_BIN_WIDTH);
        if (data.length <= maxBins) {
            return data; // No need to aggregate if data fits within the bins
        }

        const aggregatedData: number[] = [];
        const groupSize = Math.ceil(data.length / maxBins);

        for (let i = 0; i < data.length; i += groupSize) {
            const group = data.slice(i, i + groupSize);
            aggregatedData.push(Math.max(...group));
        }

        return aggregatedData;
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        if (canvas.width !== canvasSize.width + MAIN_CANVAS_AXIS_SIZE) {
            canvas.width = canvasSize.width + MAIN_CANVAS_AXIS_SIZE;
            canvas.height = HISTOGRAM_HEIGHT;
            setWidth(canvasSize.width + MAIN_CANVAS_AXIS_SIZE);
        }

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        ctx.clearRect(0, 0, width, HISTOGRAM_HEIGHT);

        if (!heatmapData) return;

        const padding = MAIN_CANVAS_AXIS_SIZE;
        const axisColor = "#cccccc";
        const gridColor = "#444444";
        const binColor = "#4caf50";
        const labelColor = "#ffffff";

        const drawableWidth = width - padding;
        const drawableHeight = HISTOGRAM_HEIGHT - padding - 15;

        const aggregatedData = aggregateData(data, drawableWidth);

        // Vertical ticks
        const numVerticalTicks = 10;
        for (let i = 0; i < numVerticalTicks - 1; i++) {
            const tickValue =
                heatmapData.minValue + (i * (heatmapData.maxValue - heatmapData.minValue)) / (numVerticalTicks - 1);
            const y = HISTOGRAM_HEIGHT - padding - (i * drawableHeight) / (numVerticalTicks - 1);

            ctx.strokeStyle = gridColor;
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(padding, y);
            ctx.lineTo(width, y);
            ctx.stroke();

            // Thick tick
            ctx.strokeStyle = labelColor;
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(padding, y);
            ctx.lineTo(padding - 5, y);
            ctx.stroke();

            ctx.fillStyle = labelColor;
            ctx.font = "12px Arial";
            ctx.textAlign = "right";
            ctx.textBaseline = "middle";
            ctx.fillText(tickValue.toFixed(1), padding - 10, y);
        }

        // Add dB label at top-left of vertical axis
        ctx.fillStyle = labelColor;
        ctx.font = "12px Arial";
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
        ctx.fillText("dB", padding - 15, padding - 30);

        // Horizontal ticks
        const numHorizontalTicks = Math.trunc((width + MAIN_CANVAS_AXIS_SIZE) / 100);
        for (let i = 0; i < numHorizontalTicks; i++) {
            const colValue =
                visibleArea.fromCol + (i * (visibleArea.toCol - visibleArea.fromCol)) / (numHorizontalTicks - 1);

            const tickValue =
                heatmapData.start_freq +
                (colValue / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq);

            const x = padding + (i * drawableWidth) / (numHorizontalTicks - 1);

            ctx.strokeStyle = gridColor;
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, HISTOGRAM_HEIGHT - padding);
            ctx.stroke();

            // Thick tick
            ctx.strokeStyle = labelColor;
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(x, HISTOGRAM_HEIGHT - padding);
            ctx.lineTo(x, HISTOGRAM_HEIGHT - padding + 5);
            ctx.stroke();

            ctx.fillStyle = labelColor;
            ctx.font = "12px Arial";
            ctx.textAlign = "center";
            ctx.textBaseline = "top";
            ctx.fillText((tickValue / hertz_divider).toFixed(1) + ` ${hertz_unit}`, x, HISTOGRAM_HEIGHT - padding + 10);
        }

        // Draw PSDdiagram bins
        const binWidth = drawableWidth / aggregatedData.length;
        aggregatedData.forEach((value, index) => {
            const binHeight =
                ((value - heatmapData.minValue) / (heatmapData.maxValue - heatmapData.minValue)) * drawableHeight;
            const clampedBinHeight = Math.max(0, binHeight);

            ctx.fillStyle = binColor;
            ctx.fillRect(
                padding + index * binWidth,
                HISTOGRAM_HEIGHT - padding - clampedBinHeight,
                binWidth - 2,
                clampedBinHeight,
            );
        });

        // Draw axis lines
        ctx.strokeStyle = axisColor;
        ctx.lineWidth = 2;

        ctx.beginPath();
        ctx.moveTo(padding, 0);
        ctx.lineTo(padding, HISTOGRAM_HEIGHT - padding);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(padding, HISTOGRAM_HEIGHT - padding);
        ctx.lineTo(width, HISTOGRAM_HEIGHT - padding);
        ctx.stroke();

        // If hoverCoords is within bounds, compute and show the time in the top-right corner
        if (
            hoverCoords.y >= 0 &&
            hoverCoords.y < heatmapData.height &&
            hoverCoords.x >= 0 &&
            hoverCoords.x < heatmapData.width
        ) {
            const time =
                heatmapData.start_time +
                (hoverCoords.y / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time);
            ctx.fillStyle = labelColor;
            ctx.font = "15px Arial";
            ctx.textAlign = "center";
            ctx.textBaseline = "top";
            // Draw at top right corner - adjust offsets as needed
            ctx.fillText(
                "Selected time: " + time.toFixed(1) + " s",
                MAIN_CANVAS_AXIS_SIZE + (width - MAIN_CANVAS_AXIS_SIZE) / 2,
                10,
            );
        }
    }, [data, heatmapData, width, HISTOGRAM_HEIGHT, visibleArea, canvasSize]);

    return (
        <canvas
            ref={canvasRef}
            className="histogram"
        />
    );
};

export default Histogram;
