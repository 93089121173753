// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useEffect, useState } from "react";
import "./ControlPanel.css";
import { fetchMatrix, fetchRestMatrix, formatPath } from "../utils/utils";
import { useCacheStore } from "../utils/store";
import Toggle from "./Toggle";
import Minimap from "./Minimap";
import TreeView, { TreeNode } from "./TreeView";
import { ALMOST_ZERO, API_URL, version } from "../utils/constants";

const ControlPanel: React.FC = () => {
    const {
        selectedAntenna,
        setSelectedAntenna,
        selectedAggregation,
        setAggregation,
        setError,
        setLoading,
        heatmapData,
        setHeatmapData,
        initVoxels,
        getNextBoxId,
        reset,
        selectedPath,
        setSelectedPath,
        loading,
        updateHeatmapData,
    } = useCacheStore();
    const { showVoxels, setShowVoxels } = useCacheStore();
    const { editingMode, setEditingMode } = useCacheStore();

    const [isTreeViewOpen, setIsTreeViewOpen] = useState<boolean>(true);
    const [files, setFiles] = useState<TreeNode[]>([]);

    useEffect(() => {
        const voxelTransformator = (voxel: Voxel[], height: number) => {
            let boxes: BoundingBox[] = [];
            for (let i = 0; i < voxel.length; i++) {
                const id = getNextBoxId();
                const longVoxel =
                    Math.abs(voxel[i].startRow) < ALMOST_ZERO && Math.abs(voxel[i].endRow - height + 1) < ALMOST_ZERO;
                boxes.push({
                    id: id,
                    intervalId: 0,
                    start: { col: voxel[i].startCol, row: voxel[i].startRow },
                    end: { col: voxel[i].endCol, row: voxel[i].endRow },
                    type: longVoxel ? "longVoxel" : "rectangle",
                    selected: false,
                });
            }
            initVoxels(boxes);
        };
        if (!selectedPath) {
            setHeatmapData(null);
            setLoading(false);
            setError("Please choose a file from the tree view");
            return;
        }
        setLoading(true);
        setError("");
        setHeatmapData(null);
        fetchMatrix(selectedPath)
            .then(({ voxels, ...data }: ResponseHeatmapData) => {
                setHeatmapData(data);
                voxelTransformator(voxels, data.height);
                setError("");
                setLoading(false);
                fetchRestMatrix(
                    selectedPath,
                    Object.keys(data.images).length,
                    Object.keys(data.images["0"]),
                    updateHeatmapData,
                );
            })
            .catch((error) => {
                setError(error.message || "Error fetching data");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [selectedPath]);

    useEffect(() => {
        fetch(`${API_URL}/spectrums`, { credentials: "include" })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Failed to fetch spectrum data");
                }
            })
            .then((data: TaggingFiles[]) => {
                const transformedData = data.map(
                    (tf, index) =>
                        ({
                            id: index.toString(),
                            name: tf.path,
                            type: "file",
                            status: tf.status,
                        }) as TreeNode,
                );
                setFiles(transformedData);
            })
            .catch((error) => {
                console.error("Error fetching spectrum data:", error);
            });
    }, [isTreeViewOpen]);

    const handleButtonClick = (index: number, aggregation: string) => {
        setSelectedAntenna(index);
        setAggregation(aggregation);
    };

    const toggleTreeView = () => {
        setIsTreeViewOpen(!isTreeViewOpen);
    };

    const handleFileSelect = (filePath: string) => {
        toggleTreeView();
        if (selectedPath === filePath) {
            return;
        }
        reset();
        setSelectedPath(filePath);
    };

    // Function to handle clicking on the overlay
    const handleOverlayClick = () => {
        if (isTreeViewOpen) {
            setIsTreeViewOpen(false);
        }
    };

    useEffect(() => {
        if (isTreeViewOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        // Cleanup on unmount
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isTreeViewOpen]);

    useEffect(() => {
        const newValue = editingMode && showVoxels;
        setEditingMode(newValue);
    }, [showVoxels, editingMode, setEditingMode]);

    // Handle Escape key to close side panel
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape" && isTreeViewOpen) {
                setIsTreeViewOpen(false);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isTreeViewOpen, loading]);

    return (
        <div className="control-panel-container">
            <div className={"hamburger-menu"}>
                <button
                    className={`hamburger-menu ${isTreeViewOpen ? "active" : ""}`}
                    onClick={toggleTreeView}
                    aria-label="Toggle File Explorer"
                    tabIndex={0}
                    onKeyUp={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                            toggleTreeView();
                        }
                    }}
                >
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </button>
            </div>
            <div className={`control-panel-inside-container ${isTreeViewOpen ? "open" : ""}`}>
                {/* Side Panel */}
                <div className={`side-panel ${isTreeViewOpen ? "open" : ""}`}>
                    <p>Recording Explorer</p>
                    <TreeView data={files} onFileSelect={handleFileSelect} />
                </div>

                {/* Left Panel Content */}
                <div className="control-panel-inside">
                    <h2>PSD Tagger v{version}</h2>
                    {heatmapData && (
                        <>
                            {<p>Sampling rate: {heatmapData.sampling_rate} Hz</p>}
                            <p>
                                Selected recording: <br/>
                                <span style={{ whiteSpace: 'pre-wrap' }}>
                                    {formatPath(selectedPath || "", 20)}
                                </span>
                            </p>
                            <div className="scrollable-table-container">
                                <table className="button-table">
                                    <thead>
                                        <tr>
                                            <th>Receivers</th>
                                            {Object.keys(heatmapData.images["0"]).map((key) => (
                                                <th key={key}>{key}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(heatmapData.images).map((index) => (
                                            <tr key={index}>
                                                <td>{index}</td>
                                                {Object.keys(heatmapData.images[index]).map((key) => (
                                                    <td key={key}>
                                                        <button
                                                            disabled={!heatmapData.images[index][key]}
                                                            onClick={() => handleButtonClick(parseInt(index, 10), key)}
                                                            className={`button ${
                                                                selectedAntenna === parseInt(index, 10) &&
                                                                selectedAggregation === key
                                                                    ? "active"
                                                                    : ""
                                                            }`}
                                                        >
                                                            {key}
                                                        </button>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                    <div className="toggles">
                        <Toggle text={"Show voxels"} state={showVoxels} setState={setShowVoxels} />
                        <Toggle text={"Edit mode"} state={editingMode} setState={setEditingMode} />
                    </div>
                    <h3 className="minimap-title">Minimap</h3>
                    <div className="minimap-container">
                        <Minimap />
                    </div>
                </div>
            </div>

            {/* Overlay */}
            {isTreeViewOpen && <div className="overlay" onClick={handleOverlayClick}></div>}
        </div>
    );
};

export default ControlPanel;
