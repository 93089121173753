// Copyright (C) 2024 Xtremis, All rights reserved

export const API_URL = process.env.REACT_APP_SPECTRUM_API_URL || "http://localhost:5151";

export const MAIN_CANVAS_AXIS_SIZE: number = 55;
export const HORIZONTAL_AXIS_HEIGHT: number = 30;
export const LONG_VOXEL_PANEL_HEIGHT = 20;
export const MIN_BIN_WIDTH = 4; // Minimum width for each bin
export const HISTOGRAM_HEIGHT: number = 250;
export const hertz_divider = 1000000;
export const hertz_unit = "MHz";

export const blue = "rgba(4, 19, 219, 1.0)";
export const red = "rgba(255, 0, 0, 1.0)";
export const red_box_background = "rgba(255, 0, 0, 0.2)";
export const axis_cross_line_color = "#1c0f3b"; // line color for cross lines/axes

export const ALMOST_ZERO = 1e-6;
export const HISTORY_SIZE = 30;
export const MAX_ZOOM: number = 15;

export const HANDLE_SIZE = 6;
export const HALF_HANDLE_SIZE = HANDLE_SIZE / 2;

export const version = "0.1.2";
