// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useEffect, useRef, useState } from "react";
import { MAIN_CANVAS_AXIS_SIZE, LONG_VOXEL_PANEL_HEIGHT } from "../utils/constants";
import { useCacheStore } from "../utils/store";

interface LongVoxelPanelProps {
    numBins: number;
    imageWidth: number;
}

const LongVoxelPanel: React.FC<LongVoxelPanelProps> = ({ numBins, imageWidth }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isSelecting, setIsSelecting] = useState<{ id: number; startIdx: number; endIdx: number } | null>(null);
    const [visibleStartBin, setVisibleStartBin] = useState(0);
    const [visibleEndBin, setVisibleEndBin] = useState(numBins - 1);
    const {
        scale,
        offset,
        voxels,
        getNextBoxId,
        deselectVoxels,
        showVoxels,
        updateVoxel,
        heatmapData,
        persistVoxels,
        editingMode,
        canvasSize,
    } = useCacheStore();

    useEffect(() => {
        if (canvasRef.current) {
            canvasRef.current.width = canvasSize.width;
            canvasRef.current.height = LONG_VOXEL_PANEL_HEIGHT;
        }
        drawBins();
    }, [numBins, voxels, scale, offset, imageWidth, canvasSize.width, isSelecting]);

    const drawBins = () => {
        if (!canvasRef.current) return;
        const ctx = canvasRef.current.getContext("2d");
        if (!ctx) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const displayedWidth = rect.width;
        const displayedHeight = rect.height;

        ctx.clearRect(0, 0, displayedWidth, displayedHeight);

        // Compute the visible bin range from MainCanvas
        const totalImageWidth = imageWidth * scale.x;
        const visibleStartX = -offset.x;
        const visibleEndX = -offset.x + canvasSize.width;

        const fractionStart = visibleStartX / totalImageWidth;
        const fractionEnd = visibleEndX / totalImageWidth;

        const computedVisibleStartBin = Math.max(0, Math.floor(fractionStart * numBins + 1));
        const computedVisibleEndBin = Math.min(numBins, Math.floor(fractionEnd * numBins - 1));

        setVisibleStartBin(computedVisibleStartBin);
        setVisibleEndBin(computedVisibleEndBin);

        const numVisibleBins = computedVisibleEndBin - computedVisibleStartBin + 1;
        const binWidth = displayedWidth / numVisibleBins;

        // Draw bins
        for (let i = computedVisibleStartBin; i <= computedVisibleEndBin; i++) {
            const x = (i - computedVisibleStartBin) * binWidth;

            let isSelected = false;
            if (showVoxels) {
                // Check if this bin is within any of the selected intervals
                voxels.forEach(({ start, end, type }) => {
                    if (type !== "longVoxel") return;
                    if (i >= start.col && i <= end.col) {
                        isSelected = true;
                    }
                });
                // Also check if it's being currently selected
                if (isSelecting) {
                    const minBin = Math.min(isSelecting.startIdx, isSelecting.endIdx);
                    const maxBin = Math.max(isSelecting.startIdx, isSelecting.endIdx);
                    if (i >= minBin && i <= maxBin) {
                        isSelected = true;
                    }
                }
            }

            ctx.fillStyle = isSelected ? "blue" : "lightgray";
            ctx.fillRect(x, 0, binWidth, displayedHeight);
            ctx.strokeStyle = "black";
            ctx.strokeRect(x, 0, binWidth, displayedHeight);
        }
    };

    const getBinIndex = (mouseX: number) => {
        if (!canvasRef.current) return -1;

        const rect = canvasRef.current.getBoundingClientRect();
        const displayedWidth = rect.width;
        const numVisibleBins = visibleEndBin - visibleStartBin + 1;
        const binWidth = displayedWidth / numVisibleBins;
        const binOffset = Math.floor(mouseX / binWidth);
        return visibleStartBin + binOffset;
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!editingMode) return;
        deselectVoxels();
        if (!showVoxels) return;
        const rect = canvasRef.current!.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const binIndex = getBinIndex(mouseX);
        if (binIndex >= 0 && binIndex < numBins) {
            setIsSelecting({ id: getNextBoxId(), startIdx: binIndex, endIdx: binIndex });
        }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!editingMode) return;
        if (isSelecting && showVoxels) {
            const rect = canvasRef.current!.getBoundingClientRect();
            const mouseX = e.clientX - rect.left;
            const binIndex = getBinIndex(mouseX);
            if (binIndex >= 0 && binIndex < numBins && isSelecting.startIdx !== binIndex) {
                setIsSelecting({ ...isSelecting, endIdx: binIndex });
                updateVoxel({
                    id: isSelecting.id,
                    start: { row: 0, col: Math.min(isSelecting.startIdx, binIndex) },
                    end: { row: heatmapData!.height - 1, col: Math.max(isSelecting.startIdx, binIndex) },
                    type: "longVoxel",
                    selected: true,
                });
            }
        }
    };

    const handleMouseStop = () => {
        if (!editingMode) return;
        if (isSelecting) {
            setIsSelecting(null);
            persistVoxels();
        }
    };

    return (
        <canvas
            ref={canvasRef}
            style={{
                width: `calc(100% - ${MAIN_CANVAS_AXIS_SIZE}px)`,
                height: `${LONG_VOXEL_PANEL_HEIGHT}px`,
                border: "0px",
                cursor: "pointer",
                marginTop: "10px",
                marginLeft: `${MAIN_CANVAS_AXIS_SIZE}px`,
                flexShrink: 0,
                boxSizing: "border-box",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseStop}
            onMouseLeave={handleMouseStop}
        />
    );
};

export default LongVoxelPanel;
