// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useEffect, useState } from "react";
import DataHandlingPanel from "./components/DataHandlingPanel";
import "./App.css";
import ControlPanel from "./components/ControlPanel";
import { API_URL } from "./utils/constants";

const App: React.FC = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const preventZoom = (event: WheelEvent | KeyboardEvent) => {
            if ((event as WheelEvent).ctrlKey && ((event as WheelEvent).deltaY || (event as WheelEvent).deltaX)) {
                event.preventDefault();
            }
            if (
                ((event as KeyboardEvent).ctrlKey || (event as KeyboardEvent).metaKey) &&
                ((event as KeyboardEvent).key === "+" || (event as KeyboardEvent).key === "-")
            ) {
                event.preventDefault();
            }
        };

        window.addEventListener("wheel", preventZoom, { passive: false });
        window.addEventListener("keydown", preventZoom, { passive: false });
        return () => {
            window.removeEventListener("wheel", preventZoom);
            window.removeEventListener("keydown", preventZoom);
        };
    }, []);

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch(`${API_URL}/session`, {
                    method: "GET",
                    credentials: "include",
                });
                if (response.ok) {
                    setLoggedIn(true);
                } else {
                    setLoggedIn(false);
                }
            } catch (error) {
                console.error("Error checking session:", error);
            }
        };
        checkSession().catch((error) => console.error("Error checking session:", error));
    }, []);

    const handleLogin = async () => {
        try {
            const response = await fetch(`${API_URL}/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ username, password }),
                credentials: "include",
            });
            const data = await response.json();
            if (response.ok) {
                setLoggedIn(true);
                setPassword("");
                setMessage("Login successful!");
            } else {
                setMessage(data.message || "Login failed");
            }
        } catch (error) {
            console.error("Error:", error);
            setMessage("An error occurred during login.");
        }
    };

    const handleLogout = async () => {
        try {
            const response = await fetch(`${API_URL}/logout`, {
                method: "POST",
                credentials: "include",
            });
            const data = await response.json();
            if (response.ok) {
                setLoggedIn(false);
                setMessage("Logged out successfully");
            } else {
                setMessage(data.message || "Logout failed");
            }
        } catch (error) {
            console.error("Error:", error);
            setMessage("An error occurred during logout.");
        }
    };

    return (
        <div className="container">
            {!loggedIn ? (
                <div className="login-div">
                    <h1>Login</h1>
                    {message && <p className="error-message">{message}</p>}
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="login-input"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="login-input"
                    />
                    <button onClick={handleLogin} className="login-button">
                        Login
                    </button>
                </div>
            ) : (
                <>
                    <div className="control-panel">
                        <ControlPanel />
                    </div>
                    <div className="data-handling-panel">
                        <DataHandlingPanel onLogout={handleLogout} />
                    </div>
                </>
            )}
        </div>
    );
};

export default App;
