// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useState } from "react";
import "./TreeView.css";
import { updateTaggingStatus } from "../utils/apiClient";
import { formatPath } from "../utils/utils";

export interface TreeNode {
    id: string;
    name: string;
    type: "folder" | "file";
    status: TaggingStatus;
    children?: TreeNode[];
}

interface TreeViewProps {
    data: TreeNode[];
    onFileSelect: (filePath: string) => void;
}

const TreeView: React.FC<TreeViewProps> = ({ data, onFileSelect }) => {
    return (
        <div className="tree-view">
            {data.map((node) => (
                <TreeNodeComponent key={node.id} node={node} onFileSelect={onFileSelect} />
            ))}
        </div>
    );
};

interface TreeNodeComponentProps {
    node: TreeNode;
    onFileSelect: (filePath: string) => void;
    level?: number;
    parentPath?: string;
}

const TreeNodeComponent: React.FC<TreeNodeComponentProps> = ({ node, onFileSelect, level = 0, parentPath = "" }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [status, setStatus] = useState<TaggingStatus>(node.status);

    const hasChildren = node.type === "folder" && node.children && node.children.length > 0;
    const paddingLeft = 20 * level;

    const handleClick = () => {
        if (node.type === "folder") {
            setExpanded(!expanded);
        } else {
            const filePath = parentPath ? `${parentPath}/${node.name}` : node.name;
            onFileSelect(filePath);
        }
    };

    return (
        <div>
            <div className={`tree-node ${node.type} ${status}`} style={{ paddingLeft }} onClick={handleClick}>
                {hasChildren && (
                    <span className={`tree-icon ${expanded ? "expanded" : "collapsed"}`}>{expanded ? "📂" : "📁"}</span>
                )}
                {!hasChildren && <span className="tree-icon">📄</span>}
                <span style={{ whiteSpace: 'pre-wrap' }} className="tree-name"> {formatPath(node.name || "", 20)}</span>
                <div onClick={(e) => e.stopPropagation()}>
                    <select
                        className="status-selector"
                        value={status}
                        onChange={(e) => {
                            updateTaggingStatus(node.name, e.target.value).catch(() => {
                                window.alert("Error updating tagging status");
                            });
                            setStatus(e.target.value as TaggingStatus);
                        }}
                    >
                        <option value="NEW">NEW</option>
                        <option value="WIP">WIP</option>
                        <option value="DONE">DONE</option>
                    </select>
                </div>
            </div>
            {hasChildren && expanded && (
                <div>
                    {node.children!.map((child) => (
                        <TreeNodeComponent
                            key={child.id}
                            node={child}
                            onFileSelect={onFileSelect}
                            level={level + 1}
                            parentPath={parentPath ? `${parentPath}/${node.name}` : node.name}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default TreeView;
